<template>
  <div>
    <a-card :title="isEdit ? '优惠券详情' : '新建优惠券'">
      <a-form-model
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
        :model="stateForm"
        ref="stateFormRef"
      >
      <div v-if="countInfo" class="count-info">
        已领取 {{countInfo.received_count}} 张，已使用 {{countInfo.use_count}} 张，已领取未使用 {{countInfo.received_not_use_count}} 张
      </div>
        <!-- 普通券/城市券 -->
        <template v-if="stateForm.admin_type == 1">
          <a-form-model-item class="mb-2" label="分类" prop="belong">
            <span v-if="stateForm.belong==1">商城券</span>
            <span v-if="stateForm.belong==2">城市券</span>
          </a-form-model-item>
          <a-form-model-item v-if="stateForm.belong==1"
              class="mb-2" label="优惠券类型" :rules="requiredRule" prop="type">
            <a-radio-group v-if="!isEdit"
              name="radioGroup" v-model="stateForm.type" @change="changeType">
              <a-radio value="1">普通礼券</a-radio>
              <a-radio value="2">生日礼券</a-radio>
              <a-radio value="3">补偿专用</a-radio>
            </a-radio-group>
            <template v-else>
              <span v-if="stateForm.type==1">普通礼券</span>
              <span v-if="stateForm.type==2">生日礼券</span>
              <span v-if="stateForm.type==3">补偿专用</span>
            </template>
          </a-form-model-item>
          <!-- <a-form-model-item v-if="stateForm.belong==2"
              class="mb-2" label="使用城市" :rules="requiredRule" prop="outlets_city_adcode">
            <a-select v-if="!isEdit"
                v-model="stateForm.outlets_city_adcode" style="width: 120px;">
              <a-select-option value="">请选择城市</a-select-option>
              <a-select-option
                v-for="(item, index) in manageCityList"
                :key="index"
                :value="item.adcode"
              >{{ item.name }}</a-select-option>
            </a-select>
            <span v-else>{{ stateForm.outlets_city_name }}</span>
          </a-form-model-item> -->
          <a-form-model-item class="mb-2" label="可用区域" :rules="requiredRule">
            <template v-if="stateForm.belong==2">
              <a-select v-if="!isEdit" style="width: 120px;" placeholder="请选择城市" 
                v-model="stateForm.outlets_city_adcode">
                <a-select-option
                  v-for="(item, index) in manageCityList"
                  :key="index"
                  :value="item.adcode">{{ item.name }}</a-select-option>
              </a-select>
              <span v-else>{{ stateForm.outlets_city_name }}</span>
            </template>
            <span v-else>全国</span>
            <div class="ml-4 inline">
              <a-select v-if="!isEdit" style="width: 120px;" placeholder="请选择场景"
                v-model="stateForm.use_scene">
                <a-select-option
                  v-for="id in Object.keys(couponScene)"
                  :value="id"
                  :key="id">{{ couponScene[id] }}</a-select-option>
              </a-select>
              <span v-else>{{ formatScene(stateForm.use_scene) }}</span>
            </div>
          </a-form-model-item>
          <a-form-model-item v-if="stateForm.belong==2 && stateForm.outlets_city_adcode"
              class="mb-2" label="门店限制" prop="outlets_id">
            <div class="flex" v-if="!isEdit">
              <a-select style="width: 256px;" show-search allowClear placeholder="搜索门店（不选择则不限制）"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @search="searchOutletsList"
                v-model="stateForm.outlets_id">
                <a-select-option v-for="(item, index) in outletsList"
                   :key="index"
                   :value="item.outlets_id">{{item.name}}</a-select-option>
              </a-select>
              <div class="ml-2 inline" v-if="stateForm.outlets_id">
                <a-checkbox v-model="stateForm.onlyPick">仅自提</a-checkbox>
              </div>
            </div>
            <div v-else>
              <span>{{stateForm.outlets_name}}</span>
              <span class="ml-4">
                <template v-if="stateForm.s_p_d == 1">仅自提</template>
                <template v-if="stateForm.s_p_d == 2">仅配送</template>
              </span>
            </div>
          </a-form-model-item>
        </template>
        <!-- 财务券 -->
        <template v-if="stateForm.admin_type == 2">
          <a-form-model-item class="mb-2" label="发券的财务账户" :rules="requiredRule" prop="financial_account_id">
            <a-select v-if="!isEdit" style="width: 560px;" placeholder="选择财务账户"
              v-model="stateForm.financial_account_id" >
              <a-select-option
                v-for="(item, index) in manageFinanceList"
                :key="index" :value="item.id">{{ item.name }}</a-select-option>
            </a-select>
            <span>{{ stateForm.financial_account_name }}</span>
          </a-form-model-item>
          <a-form-model-item class="mb-2" label="可用场景" :rules="requiredRule" prop="use_scene">
            <a-select v-if="!isEdit" style="width: 560px;" placeholder="请选择场景"
              v-model="stateForm.use_scene">
              <a-select-option
                v-for="id in Object.keys(couponScene)"
                :value="id"
                :key="id">{{ couponScene[id] }}</a-select-option>
            </a-select>
            <span v-else>{{ formatScene(stateForm.use_scene) }}</span>
          </a-form-model-item>
        </template>
        <a-form-model-item class="mb-2" :rules="requiredRule" prop="name" label="优惠券名称">
          <a-input v-if="!isEdit" style="width: 560px;" placeholder="请输入优惠券名称（用户可见）"
            :maxLength="21" v-model="stateForm.name" />
          <span v-else>{{stateForm.name}}</span>
        </a-form-model-item>
        <a-form-model-item class="mb-2" :rules="requiredRule" prop="name_interior" label="内部备注名">
          <a-input style="width: 560px;" placeholder="内部备注名"
            :maxLength="30" v-model="stateForm.name_interior" />
        </a-form-model-item>

        <template v-if="stateForm.admin_type == 2">
          <a-form-model-item class="mb-2" :rules="requiredRule" prop="settlement_amount" label="内部结算金额">
            <template v-if="!isEdit">
              <a-input-number v-model="stateForm.settlement_amount" :step="0.01" />
              <span class="ml-2">元</span>
            </template>
            <span v-else>{{stateForm.settlement_amount}} 元</span>
          </a-form-model-item>
        </template>

        <a-form-model-item class="mb-2" :rules="requiredRule" prop="discount_amount" label="券面金额">
          <template v-if="!isEdit">
            <a-input-number v-model="stateForm.discount_amount" :step="0.01" />
            <span class="ml-2">元</span>
          </template>
          <span v-else>{{stateForm.discount_amount}} 元</span>
        </a-form-model-item>
        <a-form-model-item class="mb-2" :rules="requiredRule" prop="transaction_minimum" label="消费门槛">
          <template v-if="!isEdit">
            <a-input-number v-model="stateForm.transaction_minimum" :step="0.01" />
            <span class="ml-2">元</span>
          </template>
          <span v-else>{{stateForm.transaction_minimum}} 元</span>
        </a-form-model-item>

        <a-form-model-item class="mb-2" :rules="requiredRule" prop="time" label="有效期">
          <template v-if="!isEdit">
            <a-range-picker style="width: 560px;"
              v-model="stateForm.time" valueFormat="YYYY-MM-DD" />
            <div class="mt-2">
              <a-radio-group name="termType" v-model="termType" @change="changeTermType">
                <a-radio value="0">领取后长期有效（有效期内可用）</a-radio>
                <a-radio value="1">
                  领取后
                  <a-input-number style="width:90px;"
                    :disabled="termType == 0"
                    v-model="stateForm.available_day_after_receive" />
                  天后失效（有效期结束前可领）
                </a-radio>
              </a-radio-group>
            </div>
          </template>
          <template v-else>
            <span>{{showTime}}</span>
            <span v-if="termType==0" class="ml-6">领取后长期有效（有效期内可用）</span>
            <span v-if="termType==1" class="ml-6">领取后{{stateForm.available_day_after_receive}}天后失效（有效期结束前可领）</span>
          </template>
        </a-form-model-item>
        
        <a-form-model-item class="mb-2" label="发行数量" :rules="requiredRule" prop="max_coupons">
          <template v-if="!isEdit">
            <a-input-number style="width:120px;" 
              :disabled="stateForm.max_coupons === 100000000" 
              v-model="stateForm.max_coupons" />
            <span class="ml-2">张</span>
            <a-checkbox class="ml-6" :checked="stateForm.max_coupons === 100000000"
              @change="handlerMax">无限<span style="color:#aaa;">（1亿张）</span></a-checkbox>
          </template>
          <span v-else>{{stateForm.max_coupons}} 张</span>
        </a-form-model-item>

        <a-form-model-item class="mb-2" label="每人限领" :rules="requiredRule" prop="max_coupons_per_user">
          <template v-if="!isEdit">
            <a-input-number style="width:120px;"
              :disabled="stateForm.type==3" :max="100"
              v-model="stateForm.max_coupons_per_user" />
            <span class="ml-2">张</span>
          </template>
          <span v-else>{{stateForm.max_coupons_per_user}} 张</span>
        </a-form-model-item>

        <a-form-model-item
            class="mb-2" label="特殊权益" :rules="requiredRule" prop="stored_value_card_use_together">
            <a-checkbox :checked="stateForm.stored_value_card_use_together == 2"
              :disabled="stateForm.belong==2"
              @change="handlerWithStoredCard">可以和实体卡同时使用</a-checkbox>
            <a-checkbox :checked="stateForm.free_shipping == 2"
              v-if="stateForm.belong==1"
              :disabled="stateForm.belong==2"
              @change="handlerFreeDelivery">使用此券同时免快递费</a-checkbox>
        </a-form-model-item>

        <a-form-model-item class="mb-2" label="适用商品" prop="apply_goods_type">
          <a-radio-group v-if="!isEdit" default-value="1" button-style="solid"
            v-model="stateForm.apply_goods_type">
            <a-radio-button value="1">全部商品</a-radio-button>
            <a-radio-button value="2">自定义商品</a-radio-button>
          </a-radio-group>
          <template v-else>
            <span v-if="stateForm.apply_goods_type==1">全部商品</span>
            <span v-if="stateForm.apply_goods_type==2">自定义商品</span>
          </template>
        </a-form-model-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 4 }" style="margin-top:-20px;"
          v-if="stateForm.apply_goods_type==='2'">
          <div style="max-hegiht:560px;">
            <a-button
              style="margin-left:-15px"
              type="link"
              icon="plus"
              @click="handlerAddGoods"
            >添加商品</a-button>
            <base-table
              rowKey="goods_id"
              :columnsData="goodsColumn"
              :tableData="goodsList"
              :customHeight="600"
              bordered
            >
              <template #operation="{record}">
                <a-button type="link" @click="handlerDelete(record)">删除</a-button>
              </template>
            </base-table>
          </div>
        </a-form-item>

        <a-form-model-item class="mb-2" label="使用范围" :rules="requiredRule" prop="apply_range_describe">
          <a-textarea v-if="!isEdit" style="width: 560px;" placeholder="使用范围描述（用户可见）"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            v-model="stateForm.apply_range_describe" />
          <p v-else style="white-space: pre-line;">{{stateForm.apply_range_describe}}</p>
        </a-form-model-item>

        <a-form-model-item class="mb-2" label="使用须知" :rules="requiredRule" prop="description">
          <a-textarea v-if="!isEdit" style="width: 560px;" placeholder="使用须知（用户可见）"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            v-model="stateForm.description" />
          <p v-else style="white-space: pre-line;">{{stateForm.description}}</p>
        </a-form-model-item>
        
        <a-form-model-item class="mb-2" :wrapper-col="{ span: 18, offset: 6 }" prop="description">
          <a-button type="primary" @click="handlerSubmit">{{ isEdit ? "提交修改" : "提交" }}</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-card>
    <add-goods-modal
      v-if="isShowGoods"
      :show.sync="isShowGoods"
      :selectKeys="selectKeys"
      :selectRows="goodsList"
      :getDataList="getGoodsList"
      @ok="(rows)=>{goodsList = rows}"
    />
  </div>
</template>

<script>
import { requiredRule } from "@/hooks/use-form-rules"
import addGoodsModal from "@/components/select-modal/add-goods.vue"
import { 
  goodsColumn,
  couponScene,
  formatScene
} from "./columns"
import {
  add,
  edit,
  getDetailById,
  getGoodsList,
  getCountInfo
} from "@/api/activity/coupon.js"
import {
  getOutletsList,
} from "@/api/outlets"
import { 
  getMarketCityList,
  isCityManage,
  getFinanceList,
  isFinanceManage,
} from '@/utils/authData.js'

import _ from "lodash"
import { getDaysBetween, formatDate } from "@/utils/index"

export default {
  components: { addGoodsModal },
  data() {
    return {
      requiredRule,
      goodsColumn,
      couponScene,
      formatScene,
      baseGoodsList: [],
      goodsList: [],
      selectKeys: [],
      stateForm: {
        admin_type: "", // 1普通类发票、2财务类
        financial_account_id: undefined,
        financial_account_name: '',
        belong: "1",
        outlets_city_adcode: undefined,
        outlets_id: undefined,
        s_p_d: 0,
        onlyPick: false,
        use_scene: undefined,
        type: "1",
        name: "",
        name_interior: "",
        settlement_amount: 0,
        discount_amount: 0,
        transaction_minimum: 0,
        apply_range_describe: "",
        time: [],
        start_time: "",
        end_time: "",
        available_day_after_receive: 0,
        stored_value_card_use_together: 1,
        free_shipping: 1,
        max_coupons: 1,
        max_coupons_per_user: 1,
        description: "",
        apply_goods_type: "1",
        apply_goods_data: "",
      },
      showTime: "",
      termType: "0",

      goodsSearch: {
        goods_number: "",
        goods_name: "",
      },
      isShowGoods: false,

      isCityManage: isCityManage(),
      manageCityList: [],
      outletsList: [],
      isFinanceManage: isFinanceManage(),
      manageFinanceList: [],

      countInfo: null,

      loading: false,
    }
  },
  computed: {
    isEdit() {
      return this.$route.query.id ? true : false
    },
  },
  async mounted() {
    this.stateForm.admin_type = this.$route.query.admin_type || ""

    if (this.isEdit) {
      this.initData()
    }else{
      if(this.stateForm.admin_type == 1){
        this.manageCityList = await getMarketCityList(true)
        if(this.isCityManage){
          this.stateForm.belong = 2
          this.stateForm.stored_value_card_use_together = 2
          if(this.manageCityList.length == 1){
            this.stateForm.outlets_city_adcode = this.manageCityList[0].adcode
          }
        }
      }
      if(this.stateForm.admin_type == 2){
        this.manageFinanceList = await getFinanceList(true)
        if(this.isFinanceManage){
          if(this.manageFinanceList.length == 1){
            this.stateForm.financial_account_id = this.manageFinanceList[0].id
          }
        }
      } 
    }
  },
  methods: {
    getGoodsList,

    initData() {
      getDetailById({ id: this.$route.query.id }).then((res) => {
        if (res.code === 0) {
          this.stateForm = res.data
          this.stateForm.type = res.data.type.toString()
          this.stateForm.apply_goods_type = res.data.apply_goods_type.toString()
          this.stateForm.time = [
            this.stateForm.start_time,
            this.stateForm.end_time,
          ]
          this.showTime = String(this.stateForm.start_time).split(" ")[0] 
                          + " ~ " 
                          + String(this.stateForm.end_time).split(" ")[0]
          if (res.data.apply_goods_type == 2) {
            this.goodsList = res.data.apply_goods_data
          }
          this.termType = this.stateForm.available_day_after_receive > 0 ? "1" : "0"
        }
      })
      getCountInfo({ coupon_id: this.$route.query.id }).then((res)=>{
        if (res.code == 0) {
          this.countInfo = res.data
        }
      })
    },
    handlerGoodsSearch() {
      this.baseGoodsList = _.cloneDeep(this.goodsList)
      this.goodsList = []
      this.baseGoodsList.filter((el) => {
        if (
          el.goods_number.indexOf(this.goodsSearch.goods_number) != -1 ||
          el.goods_name.indexOf(this.goodsSearch.goods_name) != -1
        ) {
          this.goodsList.push(el)
        }
      })
    },

    changeType(){
      if(this.stateForm.type == "3"){
        this.stateForm.max_coupons_per_user = 100
      }
    },
    changeTermType(){
      if(this.termType == 1){
        if(this.stateForm.available_day_after_receive == 0){
          this.stateForm.available_day_after_receive = 7
        }
      }else{
        // this.stateForm.available_day_after_receive = 0
      }
    },
    handlerSubmit() {
      if(this.loading) return

      if (
        this.stateForm.time[0] &&
        getDaysBetween(formatDate(new Date()), this.stateForm.time[0]) > 90
      ) {
        this.$message.warning("优惠券开始时间需在90天内")
        return false
      }

      this.$refs.stateFormRef.validate((valid) => {
        if (valid) {
          // 如果是编辑
          if (this.$route.query.id) {
            let params = {
              id: this.$route.query.id,
              name_interior: this.stateForm.name_interior,
              stored_value_card_use_together: this.stateForm.stored_value_card_use_together,
              free_shipping: this.stateForm.free_shipping,
              apply_goods_data:
                this.stateForm.apply_goods_type == "1"
                  ? JSON.stringify([])
                  : JSON.stringify(
                      this.goodsList.map((el) => {
                        return { goods_id: el.goods_id }
                      })
                    ),
              // apply_range_describe: this.stateForm.apply_range_describe
            }
            this.loading = true
            edit(params).then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success("已保存")
                this.$router.go(-1)
              }
            })
          } else {
          // 如果是新增
            if(this.stateForm.belong==1){
              this.stateForm.outlets_city_adcode = undefined
            }else if(this.stateForm.belong==2){
              if(!this.stateForm.outlets_city_adcode){
                this.$message.info("请选择城市")
                return
              }
            }
            if(!this.stateForm.use_scene){
              this.$message.info("请选择场景")
              return
            }
            let params = { 
              ...this.stateForm,
              s_p_d: this.stateForm.onlyPick ? 1 : 0,
            }
            if(this.termType == 1){
              if(params.available_day_after_receive < 1){
                this.$message.warning("领取后n天内有效：需要大于0")
                return
              }
            }else{
              params.available_day_after_receive = 0
            }
            params.start_time = params.time[0].substr(0, 11) + " " + "00:00:00"
            params.end_time = params.time[1].substr(0, 11) + " " + "23:59:59"
            // 如果是补偿券，每人领取写死100
            if(params.type == "3"){
              params.max_coupons_per_user = 100
            }
            if (params.apply_goods_type == "1") {
              params.apply_goods_data = JSON.stringify([])
            } else {
              params.apply_goods_data = JSON.stringify(
                this.goodsList.map((el) => {
                  return { goods_id: el.goods_id }
                })
              )
            }
            this.loading = true
            add(params).then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success("新增成功！")
                this.$router.go(-1)
              }
            })
          }
        }
      })
    },
    handlerAddGoods() {
      this.selectKeys = this.goodsList.map((el) => el.goods_id)
      this.isShowGoods = true
    },
    handlerDelete(row) {
      this.selectKeys = this.selectKeys.filter((el) => el != row.goods_id)
      this.goodsList = this.goodsList.filter(
        (el) => el.goods_id != row.goods_id
      )
    },
    handlerMax(e) {
      if (e.target.checked) {
        this.stateForm.max_coupons = 100000000
      } else {
        this.stateForm.max_coupons = 1
      }
    },
    // handlerDayAfter(e) {
    //   if (e.target.checked) {
    //     this.stateForm.available_day_after_receive = 0
    //   } else {
    //     this.stateForm.available_day_after_receive = 1
    //   }
    // },
    handlerWithStoredCard(e){
      if (e.target.checked) {
        this.stateForm.stored_value_card_use_together = 2
      } else {
        this.stateForm.stored_value_card_use_together = 1
      }
    },
    handlerFreeDelivery(e){
      if (e.target.checked) {
        this.stateForm.free_shipping = 2
      } else {
        this.stateForm.free_shipping = 1
      }
    },
    async searchOutletsList (value) {
      if (value) {
        const { data, code } = await getOutletsList({
          name: value || "",
          outlets_city_adcode: this.stateForm.outlets_city_adcode || "",
          type: 1,
          status: 0,
        })
        if (code === 0) this.outletsList = data.list
      }
    },
  },
}
</script>

<style>
.count-info{
  width: 100%;
  background: #ecf6ff;
  padding: 15px 20px;
  border-radius: 5px;
}
</style>