<template>
  <div class="p-10">
    <div class="flex items-center">
      <div style="width:80px;">门店城市：</div>
      <a-select v-model="selectCity"
        @change="handlerChange"
        style="width:300px;"
        placeholder="请选择城市">
        <a-select-option v-for="item in cityList"
           :value="item.adcode"
           :key="item.adcode">{{item.name}}</a-select-option>
      </a-select>
    </div>
    <a-divider />

    <div class="flex items-center">
      <div style="width:80px;">
        <span style="color: #f5222d;" class="mr-1"></span>跑马灯：
      </div>
      <a-input v-model="formState.notice"></a-input>
    </div>
    <!-- <a-divider /> -->
    <div>
      <div>
        <div class="mt-5"
             v-for="(item,i) in advertList"
             ref="searchRef"
             :key="i">
          <a-row>
            <a-col :span="8" class="flex">
              <div style="width:80px;">
                <span style="color: #f5222d;" class="mr-1">*</span>图片：
              </div>
              <upload-image :type="isShop? '11': '10'" @remove="handlerRemove(i)" :value.sync="item.image_path" />
            </a-col>
            <a-col :span="16" class="flex">
              <span style="color: #f5222d;" class="mr-1 mt-1">*</span>
              <span class="mt-1">跳转：</span>
              <jump-type class="flex-1" type="2" :data="item" />
            </a-col>
          </a-row>
        </div>
      </div>
      <a-button v-if="advertList.length<8" class="mt-5" type="primary" @click="handlerAdd" style="margin-left: 80px;">新增广告图</a-button>
    </div>
    <a-divider />
    <a-row>
      <!-- <a-col :span="24" class="flex"> -->
        <span style="width:130px; display: inline-block;">同步到其他城市：</span>
        <a-checkbox-group v-model="selectCitys" name="checkboxgroup" :options="copyCityList" />
      <!-- </a-col> -->
    </a-row>

    <div style="text-align:center;">
      <!-- <a-button>取消</a-button> -->
      <a-button type="primary" @click="onSubmit" style="margin:0 10px;">保存</a-button>
    </div>
  </div>
</template>

<script>
import UploadImage from "@/components/upload-image"
import JumpType from "@/pages/advert-position/components/jump-type.vue"
import { getAdvertList, editAdverts } from "@/api/regional/advert.js"
import { requiredRule } from "@/hooks/use-form-rules"
export default {
  components: { UploadImage, JumpType },
  props: {
    isShop: {
      type: Boolean,
      defalut: false,
    },
  },
  data () {
    return {
      requiredRule: requiredRule,
      selectCity: undefined,
      selectCitys: [],
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      typeList: [],
      advertList: [],
      formState: { notice: "" },

      cityList: [],
    }
  },
  computed: {
    copyCityList(){
      return this.cityList.filter(el=>el.adcode!=this.selectCity).map(el=>{
        return{label: el.name, value: el.adcode}
      })
    }
  },
  mounted () {
    const list = this.$store.getters['authCityList']
    this.cityList = list
    
    this.selectCity = this.cityList.length > 0 ? this.cityList[0].adcode : undefined
    this.initData()
  },
  methods: {
    async initData () {
      const { data, code } = await getAdvertList({ outlets_city_adcode: this.selectCity })
      if (code === 0) {
        this.advertList = data.list
        this.formState.notice = data.notice
      }
    },

    onSubmit () {
      editAdverts({
        notice: this.formState.notice,
        outlets_city_adcode: this.selectCity,
        outlets_city_adcodes: this.selectCitys.join(','),
        data: JSON.stringify(this.advertList),
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success("保存成功！")
        }
      })
    },

    handlerChange(){
      this.initData()
    },

    // 新增
    handlerAdd () {
      if (this.advertList.length < 8) {
        this.advertList.push({
          edit_jump_data: { url: "" },
          edit_jump_type: 0,
          id: 0,
          image_path: "",
          sort: 1,
          title: "",
        })
      }
    },

    handlerRemove (index) {
      this.advertList.splice(index, 1)
    },
  },
}
</script>

<style lang="less">
.advert-title {
  position: absolute;
  top: 7px;
  left: 60px;
  font-weight: 600;
  font-size: 16px;
}
.border-b-1 {
  position: relative;
  border-bottom: 1px solid rgb(194, 194, 194);
  margin-bottom: 20px;
}
</style>